<template>
	<form
		:id="`reg-optin ${id}`"
		accept-charset="UTF-8"
		action="https://lp.stockstotrade.com/14daytrial/from-wl.php"
		method="GET"
		class="infusion-form form-watchlist-cta form optin_form"
		:class="alt ? 'alt' : ''"
		target="_blank"
		ref="form"
	>
		<input
			id="email"
			name="email"
			type="email"
			placeholder="Your email address"
			class="infusion-field-input-container author-watchlist-information-email-input"
			required
		/>
		<input
			type="hidden"
			name="page_id"
			:value="id"
		/>
		<input
			type="hidden"
			name="exclude_ir"
			value="true"
		/>

		<CtaButton :type="ctaAppearence || 'secondary'">
			{{ buttonText || "Sign me up for FREE Trade Alerts" }}
		</CtaButton>
	</form>
</template>
<script setup>
const { id, buttonText, ctaAppearence, alt, altHanlder } = defineProps({
	id: {
		type: String,
		required: true
	},
	buttonText: { type: String },
	ctaAppearence: { type: String },
	alt: {
		type: Boolean
	},
	altHanlder: {
		type: Function
	}
});

const form = ref(null);

const submitHandler = async () => {
	if (alt) {
		form.value.submit();

		await setTimeout(() => {
			altHanlder();
		}, 500);
	}
};

onMounted(() => {
	if (process.client && alt && form.value) {
		form.value.querySelector("button").addEventListener("click", submitHandler);
	}
});
</script>
<style lang="scss">
.form-watchlist-cta {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	gap: 12px;

	@include breakpoint(md) {
		border: 1px solid $slate-100;
		background: $slate-50;
		padding: 6px;
		border-radius: 12px;

		&:not(.alt) {
			flex-direction: row;
		}
		gap: 10px;

		&.alt {
			.btn {
				width: 100%;
			}
		}
	}

	button {
		width: 100%;
		justify-content: center;
		@include breakpoint(md) {
			width: auto;
		}
	}

	input {
		color: $slate-900;
		background: $slate-50;
		border-radius: 12px;
		padding: 10px 12px;
		font-size: 18px;
		font-weight: 400;
		line-height: 160%;
		width: 100%;
		text-align: center;

		&::placeholder {
			font-weight: 400;
			color: $slate-400;
			padding: 10px 12px;
		}

		@include breakpoint(md) {
			background-color: transparent;
			border: unset;
			padding: 10px;
			text-align: left;
			border-radius: 0;
		}
	}
}
</style>
